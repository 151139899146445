.horizontal-timeline .items {
    border-top: 3px solid #9430F2;
  }
  
  .horizontal-timeline .items .items-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
  }
  
  .horizontal-timeline .items .items-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #9430F2;
    top: 0;
  }
  
  .horizontal-timeline .items .items-list .event-date {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    width: 75px;
    margin: 0 auto;
    font-size: 0.9rem;
    padding-top: 8px;
  }
  
  @media (min-width: 1140px) {
    .horizontal-timeline .items .items-list {
      display: inline-block;
      width: 24%;
      padding-top: 45px;
    }
  
    .horizontal-timeline .items .items-list .event-date {
      top: -40px;
    }
  }

  @media (max-width: 767px) {
    .horizontal-timeline .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .horizontal-timeline .items .items-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        margin-right: 25px;
    }

  

    .horizontal-timeline .items .items-list .event-date {
        position: relative;
        top: 0;
    }
}