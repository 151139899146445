.custom-shape-divider-bottom-1724230292 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1724230292 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 115px;
}

.custom-shape-divider-bottom-1724230292 .shape-fill {
    fill: #FFFFFF;
}

.statistics-container {
    display: flex;
    justify-content: start;
    align-items: center;
}

@media (max-width: 768px) {

    /* For tablets and smaller screens */
    .statistics-container {
        justify-content: center;
    }
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    border: 0.5px solid #BF3DFF;
    border-radius: 50%;
    text-align: center;
    font-family: Arial, sans-serif;
    backdrop-filter: blur(0);
}

@media (max-width: 768px) {

    /* For tablets and smaller screens */
    .stat-item {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 490px) {

    /* For tablets and smaller screens */
    .stat-item {
        width: 100px;
        height: 100px;
    }
}

.stat-item ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(45deg, #00B5FF, #BF3DFF);
    -webkit-mask:
        linear-gradient(#00B5FF 0 0) content-box,
        linear-gradient(#BF3DFF 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.stat-number {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: .03em;
}

@media (max-width: 768px) {

    /* For tablets and smaller screens */
    .stat-number {
        font-size: 20px;
        /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {

    /* For mobile phones */
    .stat-number {
        font-size: 18px;
        /* Further reduce font size for mobile */

    }
}

.stat-label {
    font-size: 16px;
}

.stat-item-2 {
    transform: translate(-18px);
    color: white;
}

.stat-item-3 {
    transform: translate(-36px);
    color: white;
}

@media (max-width: 768px) {
    .stat-item-2 {
        transform: translate(-9px);
    }

    .stat-item-3 {
        transform: translate(-18px);
    }
}
