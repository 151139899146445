@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

$menu_WIDTH: 150px;

.menu {
  width:100%;
   cursor: pointer;
}

.menu-title {
  text-align: center;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
}

.menu:hover > .menu-title:before {
  transition:
    0.2s border-top-width ease-in,
    0.3s border-top-color;
}


.menu:hover > .menu-title:after {
  transition: 0.2s 0.2s border-bottom-width ease-out;
}


.menu-dropdown {
  position: absolute;
  background: #ffffff;
  color:#000000;
  z-index: 100;
  transition:
    0.5s padding,
    0.5s background;
}

.menu-dropdown > * {
  overflow: hidden;
  height: 30px;
  padding: 5px 10px;
  background: rgba(0,0,0,0);
  white-space: nowrap;
  transition: 
    0.5s height cubic-bezier(.73,.32,.34,1.5),
    0.5s padding cubic-bezier(.73,.32,.34,1.5),
    0.5s margin cubic-bezier(.73,.32,.34,1.5),
    0.5s 0.2s color,
    0.2s background-color;
}

.menu-dropdown > *:hover {
  background: rgba(0,0,0,0.1);
}

.menu:not(:hover) > .menu-dropdown > * {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  color: rgba(25,25,25,0);
  transition: 
    0.5s 0.1s height,
    0.5s 0.1s padding,
    0.5s 0.1s margin,
    0.3s color,
    0.6s visibility;
  z-index: 99;
}
