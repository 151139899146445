.gradient-text {
    background: linear-gradient(to right, #9430F2, #7669EF,#51B2ED);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.slider {
    /* height: 800px; */
    margin: auto;
    /* margin-top: -100px; */
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  
  .slide-track {
    display: flex;
    width: 2000px;
    animation: scroll 10s linear infinite;
  }
  
  .slide-track:hover {
    animation-play-state: paused;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100px * 9));
    }
  }
  .slide {
    /* height: 600px;
    width: 800px; */
    align-items: center;
    padding: 15px;
    perspective: 300px;
  }
  
  /* img {
    width: 100%;
    transition: transform 1s;
  }
  
  img:hover {
    transform: translateZ(20px);
    
  } */
  .slider::before,
  .slider::after {
    
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
  }
  
  .slider::before {
    left: 0;
    top: 0;
  }
  
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ("180deg");
  }