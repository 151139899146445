@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .floating-image {
    animation: float 3s ease-in-out infinite; 
    display: block;
    margin: 0 auto;
  }
  