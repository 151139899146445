@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;600&display=swap');

$transition-time: 0.3s;

.card {
  position: relative;
  width: 100%;
  margin: 10px 0;
  perspective: 1200px;
  .cover {
    position: relative;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: transform $transition-time ease;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      z-index: 2;
      transition: transform $transition-time ease;
      transform-style: preserve-3d;
      transform: translateZ(0px);

    }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 2;
      transition: background-color $transition-time ease;
      border-radius: 10px;
    }

    .back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      color: black;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      z-index: 3;
    }
  }

  &:hover .cover {
    transform: rotateX(0deg) rotateY(-180deg);

    &:before {
      transform: translateZ(30px);
    }

    &:after {
      background-color: white;
      box-shadow: 10px 10px 3px 10px rgba(125, 92, 148, 0.3);
      
    }
    
  }
}

